export const routePaths = {
  root: '/',
  home: '/home',
  teacher: '/teacher',
  shareCode: '/teacher/shareCode',
  list: '/teacher/studentList',
  group: '/teacher/group',
  createGroup: '/teacher/createGroup',
  selectGroup: '/teacher/selectedStudents',
  winner: '/teacher/winner',
  student: '/student/:code?',
  studentGroup: '/groupPage',
  selectedStudent: '/selected',
};
