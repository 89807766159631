import { AnyAction, applyMiddleware, createStore } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import thunkMiddleware, { ThunkDispatch } from 'redux-thunk';

import { appReducer } from './reducers/index.reducer';

const config = {
  key: 'root',
  storage: storage,
};

const persisted = persistReducer(config, appReducer);

const store = createStore(persisted, applyMiddleware(thunkMiddleware));
export type AppDispatch = ThunkDispatch<{}, any, AnyAction>;

export default store;
