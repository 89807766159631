import React, { lazy } from 'react';

// import 'bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter, Route, Switch } from 'react-router-dom';

import { routePaths } from './config/globalConfig';

import './styles/style.scss';

const Home = lazy(() => import('./pages/Home'));
const Teacher = lazy(() => import('./pages/Teacher'));
const Student = lazy(() => import('./pages/Student'));
const GroupPage = lazy(() => import('./pages/Student/GroupPage'));
const SelectedStudent = lazy(() => import('./pages/Student/SelectedStudent'));
const SelectedGroup = lazy(() => import('./pages/Teacher/SelectedGroup'));
const StudentGroup = lazy(() => import('./pages/Teacher/StudentGroup'));
const StudentList = lazy(() => import('./pages/Teacher/StudentList'));
const TeacherShareCode = lazy(() => import('./pages/Teacher/TeacherShareCode'));
const Winner = lazy(() => import('./pages/Teacher/Winner'));

export const App: React.FC = () => {
  return (
    <BrowserRouter basename={process.env.REACT_APP_BASE_PATH}>
      <Switch>
        <Route path={routePaths.home} component={Home} />
        <Route path={routePaths.root} exact component={Home} />
        <Route path={routePaths.teacher} exact component={Teacher} />
        <Route path={routePaths.shareCode} exact component={TeacherShareCode} />
        <Route path={routePaths.list} exact component={StudentList} />
        <Route path={routePaths.group} exact component={StudentGroup} />
        <Route path={routePaths.selectGroup} exact component={SelectedGroup} />
        <Route path={routePaths.winner} exact component={Winner} />
        <Route path={routePaths.student} exact component={Student} />
        <Route path={routePaths.studentGroup} exact component={GroupPage} />
        <Route
          path={routePaths.selectedStudent}
          exact
          component={SelectedStudent}
        />
      </Switch>
    </BrowserRouter>
  );
};
