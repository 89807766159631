import { Reducer } from 'redux';

import {
  IUserData,
  userInitialStateData,
} from '../../shared/interfaces/user.interface';
import { UserActionTypes } from '../../shared/types/user.types';

export const userReducer: Reducer<IUserData> = (
  state = userInitialStateData,
  { type, ...payload }
) => {
  switch (type) {
    case UserActionTypes.GET_USER_DATA:
      return {
        ...state,
        ...payload,
      };
    case UserActionTypes.SAVE_USER_DATA:
      return {
        ...state,
      };
    default:
      return state;
  }
};

export default userReducer;
