export interface IGroupDetails {
  groupName: string;
  students: Array<string>;
}

export interface IRecordingsDetail {
  student: string;
  audio?: string | null | ArrayBuffer;
}

export interface IRecordings {
  groupName: string;
  recordings: Array<IRecordingsDetail>;
}
export interface IReRecordings {
  groupName: string;
  recordings: Array<IRecordingsDetail>;
}
export interface ISelectedStudents {
  groupName: string;
  student: Array<string>;
  likes?: string;
  dislikes?: string;
}

export interface IWinner {
  groupName: string;
  student: Array<string>;
  likes?: string;
  dislikes?: string;
}

export interface IUserData {
  teacher: string;
  topic: string;
  question: string;
  sentence: string;
  code: string;
  link: string;
  students: Array<string>;
  numberOfGroups: string;
  groupNames: Array<string>;
  groupDetails: Array<IGroupDetails>;
  recordings: Array<IRecordings>;
  selectedStudents: Array<ISelectedStudents>;
  reRecordings: Array<IReRecordings>;
  winner: IWinner;
  reRecord: Boolean;
  showGroupStudents: Boolean;
  image: Boolean;
  allowReRecord: Boolean;
  selection: Boolean;
}

export const userInitialStateData: IUserData = {
  teacher: '',
  topic: '',
  question: '',
  sentence: '',
  code: '',
  link: '',
  students: [],
  numberOfGroups: '',
  groupNames: [],
  groupDetails: [],
  recordings: [],
  selectedStudents: [],
  reRecordings: [],
  winner: {
    groupName: '',
    student: [],
    likes: '',
    dislikes: '',
  },
  reRecord: false,
  showGroupStudents: false,
  image: false,
  allowReRecord: false,
  selection: false,
};

export const groupDetailsinitialData: IGroupDetails = {
  groupName: '',
  students: [],
};

export const recordingDetailsinitialData: IRecordings = {
  groupName: '',
  recordings: [],
};

export const winnerDetailsinitialData: IWinner = {
  groupName: '',
  student: [],
};

export const selectedDetailsinitialData: ISelectedStudents = {
  groupName: '',
  student: [],
  likes: '',
  dislikes: '',
};
