import React, { Suspense } from 'react';

import 'bootstrap/dist/css/bootstrap.css';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { persistStore } from 'redux-persist';
import { PersistGate } from 'redux-persist/integration/react';

import { App } from './App';
import ErrorBoundary from './components/ErrorBoundary';
import store from './redux/store';
import reportWebVitals from './reportWebVitals';

const persistor = persistStore(store);
// const store = storeConfig();

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate persistor={persistor} loading={null}>
        <ErrorBoundary>
          <Suspense fallback={'Loading'}>
            <App />
          </Suspense>
        </ErrorBoundary>
      </PersistGate>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
