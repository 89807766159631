import { AnyAction, CombinedState, combineReducers } from 'redux';

import { IApplicationState } from '../../shared/interfaces/rootReducer.interface';
import userReducer from './user.reducer';

export const appReducer = combineReducers({
  user: userReducer,
});

export const rootReducer = (
  state: IApplicationState,
  action: AnyAction
): CombinedState<IApplicationState> => {
  return appReducer(state, action);
};
